import { Space } from 'antd';
import React from 'react';
import { NavigationButtons, NavigationProgressBar } from '.';

interface IProps {
  goBackHandler?: () => any;
  goNextHandler?: () => any;
  step: number;
}

const Navigation: React.FC<IProps> = ({
  goBackHandler,
  goNextHandler,
  step
}) => {
  return (
    <Space direction="vertical">
      <NavigationProgressBar step={step} />
      <NavigationButtons
        goBackHandler={goBackHandler}
        goNextHandler={goNextHandler}
      />
    </Space>
  );
};

export default Navigation;
