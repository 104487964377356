import React from 'react';
import { Button, Space } from 'antd';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';

interface IProps {
  goBackHandler?: () => any;
  goNextHandler?: () => any;
}

const NavigationButtons: React.FC<IProps> = ({
  goBackHandler,
  goNextHandler
}) => {
  return (
    <>
      <Space>
        {goBackHandler && (
          <Button
            id="goBackButton"
            type="primary"
            onClick={goBackHandler}
            style={s.btn}>
            <ArrowLeftOutlined /> Back
          </Button>
        )}

        {goNextHandler && (
          <Button
            id="goNextButton"
            type="primary"
            onClick={goNextHandler}
            style={s.btn}>
            Next <ArrowRightOutlined />
          </Button>
        )}
      </Space>
    </>
  );
};

export default NavigationButtons;

const s: Stylesheet = {
  btn: {
    minHeight: 40,
    minWidth: 120
  }
};
