import React from 'react';
import { Progress } from 'antd';

const NavigationProgressBar: React.FC<{ step: number }> = ({ step }) => {
  return (
    <div style={{ width: 300 }}>
      <Progress percent={Math.round((step / 6) * 100)} />
    </div>
  );
};

export default NavigationProgressBar;
